import httpRequest from './index';
import qs from "qs";
import { productCode} from "@/utils/config";

const baseUrl = 'http://47.92.200.142:9963'
// 获取首页信息
function GetOrderList(data) {
    data.DealerCode = sessionStorage.getItem('DealerCode')
    data.ProductCode = productCode
    return httpRequest({
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            token: sessionStorage.getItem('token')
        },
        url: baseUrl + '/api/Order/GetList',
        data: qs.stringify(data)
    });
}

// 分页查询订单列表
function getPage(data) {
    data.DealerCode = sessionStorage.getItem('DealerCode')
    data.ProductCode = productCode
    return httpRequest({
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            token: sessionStorage.getItem('token')
        },
        url: baseUrl + '/api/Order/GetModelOrder',
        data: qs.stringify(data)
    });
}

export { GetOrderList, getPage }