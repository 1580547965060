<template>
<!--  <div class="common-layout">
    <el-container>
      <el-header height="200px">
        <el-row :gutter="20">
          <el-col :span="4">
            <el-card :body-style="{ padding: '0px' }">
              <div class="span1">生成图纸数</div>
              <div class="span2">{{ drawingCount }}<span>套</span></div>
              <div class="span3">较上日增加<label>{{ drawingReta }}</label>套</div>
            </el-card>
          </el-col>
          <el-col :span="4">
            <el-card :body-style="{ padding: '0px' }">
              <div class="span1">平屋顶</div>
              <div class="span2">{{ flatCount }}<span>套</span></div>
              <div class="span3">较上日增加<label>{{ flatReta }}</label>套</div>
            </el-card>
          </el-col>
          <el-col :span="4">
            <el-card :body-style="{ padding: '0px' }">
              <div class="span1">标准斜屋顶</div>
              <div class="span2">{{ slopCount }}<span>套</span></div>
              <div class="span3">较上日增加<label>{{ slopReta }}</label>套</div>
            </el-card>
          </el-col>
          <el-col :span="4">
            <el-card :body-style="{ padding: '0px' }">
              <div class="span1">外厦斜屋顶</div>
              <div class="span2">{{ outerCount }}<span>套</span></div>
              <div class="span3">较上日增加<label>{{ outerReta }}</label>套</div>
            </el-card>
          </el-col>
          <el-col :span="4">
            <el-card :body-style="{ padding: '0px' }">
              <div class="span1">复合屋顶</div>
              <div class="span2">{{ compCount }}<span>套</span></div>
              <div class="span3">较上日增加<label>{{ compReta }}</label>套</div>
            </el-card>
          </el-col>
          <el-col :span="4">
            <el-card :body-style="{ padding: '0px' }">
              <div class="span1">账户余额</div>
              <div class="span2">{{ currentTotalCoin }}<span>元</span></div>
              <div class="span3"><a href="#/project/charge">余额明细</a></div>
            </el-card>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <el-radio-group class="btn_d" v-model="check" label="size control" size="small">
          <el-radio-button label="week" @click="switchTime('week')">最近一周</el-radio-button>
          <el-radio-button label="month" @click="switchTime('month')">最近30天</el-radio-button>
          <el-radio-button label="halfYear" @click="switchTime('halfYear')">最近半年</el-radio-button>
          <el-radio-button label="year" @click="switchTime('year')">最近一年</el-radio-button>
        </el-radio-group>
        <div id="myChart" :style="{width: '100%', height: '450px'}"></div>
      </el-main>
    </el-container>
  </div>-->
  首页
</template>

<script>
// 引入echarts
import * as echarts from 'echarts'
import {onMounted, reactive, ref} from "vue";
import {GetOrderList} from "@/request/order";
import {toRefs} from "vue-demi";
export default {
  setup() {
    const check = ref('week');
    let data = reactive({
      drawingCount: 0, // 生成套数
      drawingReta: 0, // 生成套数增加
      flatCount: 0, // 平屋顶套数
      flatReta: 0, // 平屋顶增加
      slopCount: 0, // 斜屋顶套数
      slopReta: 0, // 斜屋顶增加
      outerCount: 0, // 外厦屋顶套数
      outerReta: 0, // 外厦屋顶增加
      compCount: 0, // 复合屋顶套数
      compReta: 0, // 复合屋顶增加
      currentTotalCoin: 0 // 余额
    })
    const params = reactive({
      Week: true,
      Month: false,
      HalfYear: false,
      Year: false
    })
    const tableData = reactive({
      flatData: [],
      slopData: [],
      outerData: [],
      compData: []
      // flatData: [120, 340, 230, 78, 222, 189, 245],
      // slopData: [56, 23, 63, 123, 331, 201, 234],
      // outerData: [345, 222, 111, 34, 12, 45, 179],
      // compData: [232, 111, 257, 301, 0, 13, 174]
    })
    onMounted(() => { // 需要获取到element,所以是onMounted的Hook
      methodsMap.getList();
    });
    const methodsMap = {
      getList() {
        GetOrderList(params).then(({data: res}) => {
          data.drawingCount = res.Drawing_Count
          data.drawingReta = res.Drawing_Reta
          data.flatCount = res.RoofType1_Count
          data.flatReta = res.RoofType1_Reta
          data.slopCount = res.RoofType2_Count
          data.slopReta = res.RoofType2_Reta
          data.outerCount = res.RoofType4_Count
          data.outerReta = res.RoofType4_Reta
          data.compCount = res.RoofType5_Count
          data.compReta = res.RoofType5_Reta
          data.currentTotalCoin = res.CurrentTotalCoin
          // tableData.flatData.push([...res.Week_RoofType1])
          // tableData.slopData.push([...res.Week_RoofType2])
          // tableData.outerData.push([...res.Week_RoofType4])
          // tableData.compData.push([...res.Week_RoofType5])
          let showData = {}
          if (params.Week) {
            showData = {
              flatData: res.Week_RoofType1,
              slopData: res.Week_RoofType2,
              outerData: res.Week_RoofType4,
              compData: res.Week_RoofType5,
              countData: res.RoofType_Total,
              xData: res.Week_Timelist
            }
          } else if (params.Month) {
            showData = {
              flatData: res.Month_RoofType1,
              slopData: res.Month_RoofType2,
              outerData: res.Month_RoofType4,
              compData: res.Month_RoofType5,
              countData: res.RoofType_Total,
              xData: res.Month_Timelist
            }
          } else if (params.HalfYear) {
            showData = {
              flatData: res.HalfYear_RoofType1,
              slopData: res.HalfYear_RoofType2,
              outerData: res.HalfYear_RoofType4,
              compData: res.HalfYear_RoofType5,
              countData: res.RoofType_Total,
              xData: res.HalfYear_Timelist
            }
          } else if (params.Year) {
            showData = {
              flatData: res.Year_RoofType1,
              slopData: res.Year_RoofType2,
              outerData: res.Year_RoofType4,
              compData: res.Year_RoofType5,
              countData: res.RoofType_Total,
              xData: res.Year_Timelist
            }
          }
          methodsMap.drawTable(showData)
        })
      },
      switchTime(val) {
        if (val === 'week') {
          params.Week = true;
          params.Month = false;
          params.HalfYear = false;
          params.Year = false;
        } else if (val === 'month') {
          params.Week = false;
          params.Month = true;
          params.HalfYear = false;
          params.Year = false;
        } else if (val === 'halfYear') {
          params.Week = false;
          params.Month = false;
          params.HalfYear = true;
          params.Year = false;
        } else if (val === 'year') {
          params.Week = false;
          params.Month = false;
          params.HalfYear = false;
          params.Year = true;
        }
        methodsMap.getList()
      },
      formatData() {
        console.log(tableData)
      },
      drawTable(showData) {
        let myChart = echarts.init(document.getElementById("myChart"));
        // 绘制图表
        myChart.setOption({
          title: {
            text: '生成图纸趋势'
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: ['平屋顶', '标准斜屋顶', '外厦斜屋顶', '复合屋顶','总数']
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          toolbox: {
            feature: {
              saveAsImage: {}
            }
          },
          xAxis: {
            type: 'category',
            boundaryGap: false,
            // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            data: showData.xData
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              name: '平屋顶',
              type: 'line',
              // smooth: true,
              // data: [120, 132, 101, 134, 90, 230, 210]
              data: showData.flatData
            },
            {
              name: '标准斜屋顶',
              type: 'line',
              // smooth: true,
              // data: [220, 182, 191, 234, 290, 330, 310]
              data: showData.slopData
            },
            {
              name: '外厦斜屋顶',
              type: 'line',
              // stack: 'Total',
              // smooth: true,
              // data: [150, 232, 201, 154, 190, 330, 410]
              data: showData.outerData
            },
            {
              name: '复合屋顶',
              type: 'line',
              // smooth: true,
              // data: [320, 332, 301, 334, 390, 330, 320]
              data: showData.compData
            },
            {
              name: '总数',
              type: 'line',
              // smooth: true,
              data: showData.countData
              // data: tableData.flatData
            }
          ]
        });
        window.onresize = function () { // 自适应大小
          myChart.resize();
        };
      }
    }
    return {
      check,
      ...methodsMap,
      ...toRefs(data),
    }
  }
}
</script>
<style lang="scss" scoped>
.span3 {
  font-size: 14px;
  margin-bottom: 15px;
  color: #636b83;
  label {
    color: red;
  }
}
.span2 {
  font-size: 35px;
  font-weight: bold;
}
.span1 {
  font-size: 14px;
  color: #636b83;
}
.span1, .span2, .span3 {
  margin-top: 14px;
  margin-left: 35px;
  span {
    font-size: 20px;
    margin-left: 5px;
  }
  a {
    color: #77a2ef
  }
}
.btn_d {
  margin-right: 50px;
  display: flex;
  justify-content: flex-end;
}
</style>
